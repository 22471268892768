import { NavLink } from 'react-router-dom'
import React from 'react'

import {
	CAvatar,
	CDropdown,
	CDropdownDivider,
	CDropdownHeader,
	CDropdownItem,
	CDropdownMenu,
	CDropdownToggle,
} from '@coreui/react'

import {
	cilSettings,
	cilUser,
} from '@coreui/icons'

import CIcon from '@coreui/icons-react'

import avatar8 from './../../assets/images/avatars/8.jpg'

const isTokenExpired = (token) => {
	if (!token) return true;

	const payload = JSON.parse(atob(token.split('.')[1]));
	const expiry = payload.exp * 1000; // Convert to milliseconds
	return Date.now() >= expiry;
	// window.location.href = '/admin';
};

const getToken = () => localStorage.getItem('token');
const clearToken = () => localStorage.removeItem('token');

const AppHeaderDropdown = () => {
	const token = getToken();

	const handleLogout = () => {
		clearToken();
		window.location.href = '/';
	}

	React.useEffect(() => {
		if (isTokenExpired(token)) {
			handleLogout();
		}
	}, [token]);

	return (
		<CDropdown variant="nav-item">
			<CDropdownToggle placement="bottom-end" className="py-0 pe-0" caret={false}>
				<CAvatar src={avatar8} size="md" />
			</CDropdownToggle>
			<CDropdownMenu className="pt-0" placement="bottom-end">
				<CDropdownHeader className="bg-body-secondary fw-semibold my-2">Account</CDropdownHeader>
				<CDropdownItem to="/admin/profile" as={NavLink}>
					<CIcon icon={cilUser} className="me-2" />
					Profile
				</CDropdownItem>
				<CDropdownItem href="javascript:void(0)">
					<CIcon icon={cilSettings} className="me-2" />
					Settings
				</CDropdownItem>
				{/* <CDropdownItem href="javascript:void(0)">
					<CIcon icon={cilFile} className="me-2" />
					Projects
					<CBadge color="primary" className="ms-2">
						42
					</CBadge>
				</CDropdownItem> */}
				<CDropdownDivider />
				<CDropdownItem onClick={handleLogout}>
					<CIcon className="me-2" />
					Log Out
				</CDropdownItem>
			</CDropdownMenu>
		</CDropdown>
	)
}

export default AppHeaderDropdown