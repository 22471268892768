import React from 'react';
import FooterLogo from 'assets/images/navbar-logo.png';
import FooterCe from 'assets/images/footer-ce.svg';
import Twitter from 'assets/images/icons/twitter.svg';
import LinkedIn from 'assets/images/icons/linkden.svg';
import Facebook from 'assets/images/icons/facebook.svg';
import Instagram from 'assets/images/icons/instagram.svg';
import Mail from 'assets/images/icons/message.svg';
import Phone from 'assets/images/icons/phone.svg';
import WhatsApp from 'assets/images/whats-app-contact.png';
import { NavLink } from 'react-router-dom';

function Footer() {
	return (
		<footer className='footer pt-4' id='contact'>
			<div className="container">
				<div className='d-flex justify-content-around align-items-start'>
					<div>
						<img src={FooterLogo} alt="Company Logo" className="footer-logo" />
						<ul className="mt-3">
							<li>
								The Programmable LED Show Light Product
							</li>
							<li className='py-2'>
								<h1>Follow Us</h1>
							</li>
							<li className='social-icons'>
								<a href="https://www.facebook.com/" aria-label="Facebook">
									<img src={Facebook} className='pointer' alt="Facebook" width={35} />
								</a>
								<a href="https://www.linkedin.com/" aria-label="LinkedIn" className='mx-1'>
									<img src={LinkedIn} className='pointer' alt="LinkedIn" width={35} />
								</a>
								<a href="https://www.instagram.com/" aria-label="Instagram" className='mx-1'>
									<img src={Instagram} className='pointer' alt="Instagram" width={35} />
								</a>
								<a href="https://x.com/" aria-label="x">
									<img src={Twitter} className='pointer' alt="Twitter" width={35} />
								</a>
							</li>
						</ul>
					</div>

					<div>
						<h5>Links</h5>
						<ul className="mt-3">
							<li>
								<NavLink to="/">Home</NavLink>
							</li>
							<li>
								<NavLink to="/about">About</NavLink>
							</li>
							<li>
								<NavLink to="/products">Products</NavLink>
							</li>
							<li>
								<NavLink to="/projects">Projects</NavLink>
							</li>
							<li>
								<NavLink to="/news">News</NavLink>
							</li>
							<li>
								<NavLink to="/contact">Contact</NavLink>
							</li>
						</ul>
					</div>

					<div>
						<h5>Contact Us</h5>
						<ul className="mt-3">
							<li>
								<a href="mailto:maxtronsl43@gmail.com">
									<img src={Mail} className='pointer m-1' alt="Email" width={20} />
									msl@maxtron.com
								</a>
							</li>
							<li>
								<a href="tel:+19082409346">
									<img src={Phone} className='pointer m-1' alt="Phone" width={20} />
									+971 50 116 4472
								</a>
							</li>
							<li>
								<img src={WhatsApp} className='pointer m-1' alt="Phone" width={100} />
							</li>
						</ul>
					</div>

					<div>
						<h5>Head Quarter</h5>
						<ul className="mt-3 contact-list">
							<li className="contact-item py-1">
								<a href="https://www.google.com/maps/place/Maxtron+Show+Lighting/@26.8933995,41.0104003,3.25z/data=!4m6!3m5!1s0x3ef60ba47ec7d815:0x3821de1593d8133d!8m2!3d25.6679782!4d55.8000026!16s%2Fg%2F11vdc_w275?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">
									Maxtron Show Lighting FZ LLC FZ12-04 Al Hamra Industrial Zone Ras Al Khaimah United Arab Emirates
								</a>
							</li>
							<li className="contact-item py-1">
								US Branch
							</li>
							<li className="contact-item py-1">
								<a href="tel:+1 908 240 9346">
								US +1 908 240 9346
								</a>
							</li>
							<li className="contact-item">
								<a href="https://www.google.com/maps/place/Maxtron,+SL,+Inc./@38.9535966,-77.4273258,17z/data=!3m2!4b1!5s0x89b6479c357626d3:0xec93c497113f4269!4m6!3m5!1s0x89b64775f13fffff:0xe7644121ad896ef6!8m2!3d38.9535966!4d-77.4247455!16s%2Fg%2F11vxl4wj_1?entry=ttu&g_ep=EgoyMDI0MDkxOC4xIKXMDSoASAFQAw%3D%3D" target="_blank" rel="noreferrer">
								Maxtron SL, Inc. 13800 Coppermine Rd. Herndon, VA 20171 US
								</a>
							</li>
						</ul>
					</div>

					<div>
						<img src={FooterCe} style={{ width: '150px' }} alt="Footer Logo" className="footer-logo" />
					</div>
				</div>
				<div className='d-flex justify-content-center mt-3'>
					<p>© 2024 Maxtron Show Lighting. All Rights Reserved.</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
