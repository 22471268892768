import React, { useEffect, useState } from "react";
import axios from "axios";
import DynamicTable from "components/Admin/DynamicTable";

const config = {
	headerTitle: "Projects",
	listDescription: "Here is the Projects List",
	addButtonLabel: "Add Project",
	addButtonLink: "/admin/project/add",
	editButtonLink: "/admin/project/edit",
	tableHeaders: ["#", "Name", "Owner Name", "Action"],
	tableFields: ["id", "name", "ownerName", "action"],
};

function Projects() {
	const [projects, setProjects] = useState([]);
	const [paginationData, setPaginationData] = useState({});

	const fetchData = async (page = 1) => {
		try {
			console.log(page);
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}admin/projects?page=${page}`,
				{
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`,
					},
				}
			);
			const data = await response.json();
			if (data.status) {
				setPaginationData(data.paginationData);
				setProjects(data.data);
			} else {
				console.error("Unexpected response format or status is false:", data);
			}
		} catch (error) {
			console.error("Error while fetching projects:", error);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onPageChange = async (page) => {
		await fetchData(page);
	};

	const handleDelete = async (id) => {
		const confirmed = window.confirm("Are you sure you want to delete this project?");
		if (!confirmed) {
			return;
		}

		try {
			await axios.delete(`${process.env.REACT_APP_API_URL}admin/project/delete/${id}`, {
				headers: {
					Authorization: `Bearer ${localStorage.getItem("token")}`,
				},
			});
			setProjects((prevProjects) => prevProjects.filter((project) => project._id !== id));
		} catch (error) {
			console.error("Error while deleting project:", error);
		}
	};

	const tableData = projects.map((project, index) => ({
		id: (paginationData.page - 1) * paginationData.limit + (index + 1),
		name: project.name,
		ownerName: project.ownerName,
		action: project._id,
	}));

	return (
		<DynamicTable
			config={config}
			tableData={tableData}
			handleDelete={handleDelete}
			paginationData={paginationData}
			onPageChange={onPageChange}
		/>
	);
}

export default Projects;
