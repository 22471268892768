import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
	return (
		<CFooter className="px-4">
			<div>
				Maxtronsl
				<span className="ms-1">&copy; 2024 WebTechno.</span>
			</div>
			<div className="ms-auto">
				<span className="me-1">Powered by</span>
				<a href="javascript:void(0)" target="_blank" rel="noopener noreferrer">
					Maxtronsl
				</a>
			</div>
		</CFooter>
	)
}

export default React.memo(AppFooter)